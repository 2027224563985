/*eslint-disable*/
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";

const PresentationPage = lazy(() =>
  import("views/PresentationPage/PresentationPage.js")
);
const Services = lazy(() => import("views/Services/Services.js"));
const ServiceDetails = lazy(() => import("views/Services/ServiceDetails"));
const Blog = lazy(() => import("views/Blog/Blog"));
const Profile = lazy(() => import("views/UserProfile/ProfilePage"));
const YourOrder = lazy(() => import("views/Orders/yourOrders"));
const BlogDetails = lazy(() => import("views/Blog/BlogDetails"));
const Payment = lazy(() => import("views/Payment/Payment"));
const ServiceCart = lazy(() => import("views/ServiceCart/ServiceCart.js"));
const ServiceCartDetails = lazy(() =>
  import("views/ServiceCart/ServiceCartDetails.js")
);
const ProfessionalRegistration = lazy(() =>
  import("views/RegisterPage/ProfessionalRegistration")
);

import "assets/scss/material-kit-pro-react.scss?v=1.8.0";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Suspense fallback={<div />}>
      <Switch>
       
        <Route path="/services" component={Services} />
        <Route path="/join-us" component={ProfessionalRegistration} />
        <Route path="/service-details" component={ServiceDetails} />
        <Route path="/blog" exact component={Blog} />
        <Route path="/blog/:id" exact component={BlogDetails} />
        <Route path="/your-profile" component={Profile} />
        <Route path="/your-order" component={YourOrder} />
        <Route path="/cart" component={ServiceCart} />
        <Route path="/cart-details" component={ServiceCartDetails} />
        <Route path="/payment" component={Payment} />
        <Route path="/" component={PresentationPage} />
      </Switch>
    </Suspense>
  </Router>,
  document.getElementById("root")
);
